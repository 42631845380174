import { Alert, Box, Button, Grid, InputAdornment, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewImagesUpload from "../GlobalComponent/NewImagesUpload";
import noimage from "../../Assets/noimage.png";
import CustomSelect from "../GlobalComponent/CustomSelect";
import { useGlobalForm } from "../../API/Hooks/useGlobalForm";
import { getImageUrl, handleMaxLengthInput } from "../../MIS/Global";
import SizeList from "./SizeList";
import FabricList from "./FabricList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useSubProductConfig } from "../../API/Hooks/useSubProduct";
import SubProductList from "./SubProductList";
import { editSubProduct, saveSubProduct } from "../../API/Reducer/subProductReducer";
import { getMainProductList_Option } from "../../API/Reducer/adminProductReducer";
 
const SubProduct = () => {
  const dispatch = useDispatch();
  const [searchSizeStr, setSearchSizeStr] = useState("");
  const [searchFabricStr, setSearchFabricStr] = useState("");
  const [photos, setPhotos] = useState([]);
  const [editImgUrl, setEditImgUrl] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});  

  const { files, previewFiles } = useSelector((state) => state.file);
  const { instanceIsLoading } = useSelector((state) => state.menu);
  const { subProductData } = useSelector((state) => state.subproduct);
  const { mainProductOption } = useSelector((state) => state.adminproduct);
  

  const { defaultValues, validationSchema } = useSubProductConfig();
  const onSubmit = async (data) => {    
    if (selectedProduct?.label == "" || selectedProduct?.label == undefined) {
       setSelectedProduct({ ...selectedProduct, isselect: false });
      return;
    }    
    const formData = new FormData();
    delete data["imgpath"];
    if (data?.id > 0) {
      formData.append("id", data?.id);
    }
    formData.append("mpid", selectedProduct?.id);
    formData.append("subpname", data?.subpname);    
    formData.append("subp_descp", data?.subp_descp);
    formData.append("subp_status", data?.subp_status);    

    formData.append("loginid", data?.loginid);
    if (photos) {
      formData.append("imgpath", photos?.[0]);
    }    
     dispatch(saveSubProduct(formData, reset, setPhotos,setSelectedProduct));
  };

  const onError = (errors) => {};
  const { register, handleSubmit, errors, reset, setValue, getValues } =
    useGlobalForm(defaultValues, validationSchema, onSubmit, onError);
    
   useEffect(() => {
     dispatch(getMainProductList_Option({page:1,limit:1000}))
   },[])
   
   useEffect(() => {
    if (Object.keys(subProductData).length > 0) {
      dispatch(editSubProduct(setValue));
      setEditImgUrl(getImageUrl(`${subProductData?.imgpath}`));
      setSelectedProduct({ id:subProductData?.mpid,label:subProductData?.mpname ,isselect: true });

    }
  }, [subProductData]);

  return (
    <>
     
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={instanceIsLoading}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={1} // MUI spacing system for consistency (gap: '10px' -> gap: 2)
                  margin={1}
                >
                  {/* Image Preview Section */}
                  <Box width="100%" height="250px">
                    {previewFiles.length > 0 ? (
                      previewFiles.map((preview, index) => (
                        <Box
                          key={index}
                          component="img"
                          src={preview}
                          alt={`uploaded preview ${index}`}
                          sx={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%", // Ensures the image fills the Box
                          }}
                        />
                      ))
                    ) : (
                      <Box
                        component="img"
                        src={Object.keys(subProductData).length > 0 ? editImgUrl : noimage}
                        alt="No Image"
                        sx={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%", // Ensure the placeholder image also fills the Box
                        }}
                      />
                    )}
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NewImagesUpload
                      multipleUpload={false}
                      photos={photos}
                      setPhotos={setPhotos}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <CustomSelect
                        options={mainProductOption}
                        selectText={selectedProduct?.label || "Select a Product"}
                        setSelectedValue={setSelectedProduct}
                      />
                      {selectedProduct?.isselect === false && (
                        <Typography color="error" variant="subtitle2">
                          Product Name is required
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Sub-Product Name"
                        InputLabelProps={{
                          shrink:true,
                        }}
                        inputProps={{
                          onInput: (event) => handleMaxLengthInput(event, 100),
                        }}
                        {...register("subpname")}
                        error={!!errors.subpname}
                        helperText={errors.subpname?.message}
                      />
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <TextField
                        multiline
                        // rows={3}
                        maxRows={10}
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Product Description"
                        InputLabelProps={{
                          shrink:true
                        }}
                        inputProps={{
                          onInput: (event) => handleMaxLengthInput(event, 5000),
                        }}
                        {...register("subp_descp")}
                        error={!!errors.subp_descp}
                        helperText={errors.subp_descp?.message}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                  <div className="mpfabric">
                    <div className="row1">
                      <h3>Select Fabric:</h3>
                      <div className="row2">
                       
                      <TextField
                          label="Search"
                          size="small"
                          variant="standard"
                          value={searchFabricStr}
                          onChange={(e) => {
                            setSearchFabricStr(e.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setSearchFabricStr("");
                                }}
                              >
                                <CloseIcon />
                              </InputAdornment>
                            ),
                          }}
                        />                   
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <hr className="styled-hr"></hr>
                    </div>
                    <div>
                      <FabricList searchFabricStr={searchFabricStr}/>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                  <div className="mpfabric">
                    <div className="row1">
                      <h3>Select Size:</h3>
                      <div className="row2">                      
                        <TextField
                          label="Search"
                          size="small"
                          variant="standard"
                          value={searchSizeStr}
                          onChange={(e) => {
                            setSearchSizeStr(e.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setSearchSizeStr("");
                                }}
                              >
                                <CloseIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                     
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <hr className="styled-hr"></hr>
                    </div>
                    <div>
                      <SizeList searchSizeStr={searchSizeStr}/>
                    </div>
                  </div>
                </Paper>
              </Grid>

            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: "10px" }}>
            <SubProductList />
          </Paper>
        </Grid>
      </Grid>
    </form>
    </>
  );
};

export default SubProduct;
