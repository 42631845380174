/* eslint-disable react/jsx-no-undef */
import React from "react";
import "./index.css";
import store from "../src/API/Store/store";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HomePage from "./Components/HomePage/HomePage";
import AdminProductPage from "./Components/AdminProduct/AdminProductPage";
import AdminBannerPage from "./Components/AdminBanner/AdminBannerPage";
import CategoryProductPage from "./Components/Product/CategoryProductPage";
import ProductPage from "./Components/Product/ProductPage";
import PdfGeneratorWithApi from "./Components/PDF/PdfGeneratorWithApi";
import LargeDataTable from "./Components/PDF/LargeDataTable ";
 


const root = document.getElementById("root");
const queryClient = new QueryClient();

render(
  <React.StrictMode>
    <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
           <Route path="/" element={<HomePage/>}/>
           <Route path="/pdf" element={<PdfGeneratorWithApi/>}/>
           <Route path="/pdf1" element={<LargeDataTable/>}/>

           <Route path="/products" element={<CategoryProductPage/>}/>
           <Route path="/products/xasd745sd" element={<ProductPage/>}/>

           
           <Route path="/admin/product" element={<AdminProductPage/>}/>
           <Route path="/admin/banner" element={<AdminBannerPage/>}/>
                         
        </Routes>
      </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  root
);

// s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
