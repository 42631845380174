import React, { useEffect, useState } from "react";
import "./HomeImageSlider.scss";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getBannerList } from "../../API/Reducer/bannerReducer";
import { getImageUrl } from "../../MIS/Global";
import noimage from "../../Assets/noimage.png";

const images = [
  "https://ace.playspgames.com/TEE%20BLANKA/Banners/BANNER%201.jpg",
  "https://ace.playspgames.com/TEE%20BLANKA/Banners/BANNER%202.jpg",
  "https://ace.playspgames.com/TEE%20BLANKA/Banners/BANNER%203.jpg",
  "https://ace.playspgames.com/TEE%20BLANKA/Banners/BANNER%204.jpg",
  "https://ace.playspgames.com/TEE%20BLANKA/Banners/BANNER%205.jpg",
];

function HomeImageSlider() {
  const dispatch=useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { bannerList, bannerPageData } = useSelector((state) => state.banner);
  const loadInitialData = async (data={ page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getBannerList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerList.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? bannerList.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerList.length);
  };

  return (
    

    <Box
      sx={{
        position: "relative",
        maxWidth: "100%",
        overflow: "hidden",
        height: "auto",
        // height: {
        // xs: "400px",
        // md:"auto"
        // } 
      }}
    >
      {/* Image Container */}
      <Box
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
          width: "100%",
        }}
      >
        {bannerList.map((element,index) => (
          <Box
            key={index}
            component="img"
            src={getImageUrl(`${element?.imgpath}`) || noimage}
            alt={"Banner"}
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        ))}
      </Box>

      {/* Left and Right Buttons */}
      <IconButton
        onClick={goToPrevious}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0,0,0,0.5)",
          color: "white",
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={goToNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0,0,0,0.5)",
          color: "white",
        }}
      >
        <ArrowForwardIos />
      </IconButton>

      {/* Slide Indicators */}
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "10px",
        }}
      >
        {bannerList.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: "10px",
              height: "10px",
              backgroundColor: currentIndex === index ? "white" : "gray",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Box>
    </Box>
  );
}

export default HomeImageSlider;
