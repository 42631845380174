import { createSlice } from "@reduxjs/toolkit";
import { setAlertData } from "./alertReducer";
import { apiRequest } from "../../MIS/api";
import { handleInstanceIsLoading } from "./menuReducer";
import { handlePreviewFiles } from "./fileReducer";
import { getImageUrl } from "../../MIS/Global";

const adminProductReducer = createSlice({
  name: "adminproduct",
  initialState: {
    fabricModal: false,
    sizeModal: false,
    fabricData: [],
    fabricCheckData: [],
    sizeCheckData: [],
    mainProductList: [],
    mainProductData: {},
    mainProductPageData: {},
    mainProductListLoading: false,
    mainProductOption:[],
  },

  reducers: {
    setFabricModal(state, action) {
      state.fabricModal = action.payload;
    },
    setSizeModal(state, action) {
      state.sizeModal = action.payload;
    },
    setFabricData(state, action) {
      state.fabricData = [...state.fabricData, action.payload];
    },
    setMainProductList(state, action) {
      state.mainProductList = action.payload;
    },
    setMainProductPageData(state, action) {
      state.mainProductPageData = action.payload;
    },
    setMainProductListLoading(state, action) {
      state.mainProductListLoading = action.payload;
    },
    setMainProductData(state, action) {
      state.mainProductData = action.payload;
    },
    toggleFabricCheckList(state, action) {
      const fabricId = action.payload;
      if (state.fabricCheckData.includes(fabricId)) {
         state.fabricCheckData = state.fabricCheckData.filter(fabric => fabric !== fabricId);
      } else {
         state.fabricCheckData = [...state.fabricCheckData, fabricId];
      }
    },
    toggleSizeCheckList(state, action) {
      const sizeId = action.payload;
      if (state.sizeCheckData.includes(sizeId)) {
         state.sizeCheckData = state.sizeCheckData.filter(size => size !== sizeId);
      } else {
         state.sizeCheckData = [...state.sizeCheckData, sizeId];
      }
    },
    setMainProductOption(state, action) {
      state.mainProductOption = action.payload;
    },

  },
});

export const {
  setFabricModal,
  setSizeModal,
  setFabricData,
  setMainProductList,
  setMainProductPageData,setMainProductData,
  toggleFabricCheckList,toggleSizeCheckList,setMainProductOption,
} = adminProductReducer.actions;
export default adminProductReducer.reducer;

export const handleFabricModal = (data) => {
  return async function actionData(dispatch, getState) {
    const { fabricModal } = getState().adminproduct;
    dispatch(setFabricModal(!fabricModal));
  };
};

export const handleSizeModal = (data) => {
  return async function actionData(dispatch, getState) {
    const { sizeModal } = getState().adminproduct;
    dispatch(setSizeModal(!sizeModal));
  };
};

export const saveMainProduct = (data, reset,setPhotos) => {
  return async function actionData(dispatch, getState) {
    const { mainProductData } = getState().adminproduct;
    let method="post";
    if(mainProductData?.id>0){method="patch"}    
    try {      
      const res = await apiRequest(method, "mainproduct/", data);
      if (res?.code == 200) {
        if (Object.keys(res?.data).length > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          reset();
          setPhotos([]);
          dispatch(handlePreviewFiles([]));
          dispatch(setMainProductData({}))
          await dispatch(getMainProductList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: res?.msg,
              code: 100,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());      
    }
  };
};

export const getMainProductList = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `mainproduct/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          dispatch(setMainProductList(res?.data));
          dispatch(setMainProductPageData(res?.meta));                    
        } else{
          dispatch(setMainProductList([]));
          dispatch(setMainProductPageData([])); 
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const getMainProductList_Option = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `mainproduct/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          const transformedData = res?.data.map(item => ({
            id: item?.id,
            label: item?.mpname,
          }));                    
          dispatch(setMainProductOption(transformedData));                  
        } else{
          dispatch(setMainProductOption([]));      
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const editMainProduct = (setValue) => {
  return async function actionData(dispatch, getState) {
    const { mainProductData } = getState().adminproduct;
    Object.entries(mainProductData).forEach(([key, value]) => {   
        setValue(key,value);      
    });
  }
}

export const deleteMainProduct = (data) => {
  return async function actionData(dispatch, getState) {
    dispatch(handleInstanceIsLoading());
    try {
      // POST request with custom headers
      const res = await apiRequest("delete", `mainproduct/`, data);
      if (res?.code == 200) {
        if (res?.data > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          await dispatch(getMainProductList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Delete failed!Please try again",
              code: 100,
            })
          );
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());
    }
  };
};


