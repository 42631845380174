import { createSlice } from "@reduxjs/toolkit";
import { setAlertData } from "./alertReducer";
import { apiRequest } from "../../MIS/api";
import { handleInstanceIsLoading } from "./menuReducer";
import { handlePreviewFiles } from "./fileReducer";
 
const subProductReducer = createSlice({
  name: "subproduct",
  initialState: { 
    subProductData: {},
    subProductList: [],
    subProductPageData: {},
    subProductOption:[],
  },

  reducers: {    
    setSubProductList(state, action) {
      state.subProductList = action.payload;
    },
    setSubProductPageData(state, action) {
      state.subProductPageData = action.payload;
    },   
    setSubProductData(state, action) {
      state.subProductData = action.payload;
    },
    setSubProductOption(state, action) {
      state.subProductOption = action.payload;
    },

  },
});

export const {
    setSubProductList,
    setSubProductPageData,
    setSubProductData,setSubProductOption,   
} = subProductReducer.actions;
export default subProductReducer.reducer;

 
export const saveSubProduct = (data,reset,setPhotos,setSelectedProduct) => {
  return async function actionData(dispatch, getState) {
    const { subProductData } = getState().subproduct;
    dispatch(handleInstanceIsLoading());
    let method="post";
    if(subProductData?.id>0){method="patch"}
    try {      
      const res = await apiRequest(method, "sub-product/", data);
       if (res?.code == 200) {
        if (Object.keys(res?.data).length > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          reset();
          setPhotos([]);
          setSelectedProduct({});
          dispatch(handlePreviewFiles([]));
          dispatch(setSubProductData({}));
          await dispatch(getSubProductList({ page: 1, limit: 100 }));
        } else {          
          dispatch(
            setAlertData({
              type: "error",
              msg: res?.msg,
              code: 100,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());      
    }
  };
};

export const getSubProductList = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `sub-product/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          dispatch(setSubProductList(res?.data));
          dispatch(setSubProductData(res?.meta));   
        } else{
          dispatch(setSubProductList([]));
          dispatch(setSubProductData([])); 
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const getSubProductList_Option = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `sub-product/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          const transformedData = res?.data.map(item => ({
            id: item?.id,
            label: item?.subpname,
          }));                    
          dispatch(setSubProductOption(transformedData));                  
        } else{
          dispatch(setSubProductOption([]));      
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const editSubProduct = (setValue) => {
  return async function actionData(dispatch, getState) {
    const { subProductData } = getState().subproduct;
    Object.entries(subProductData).forEach(([key, value]) => {   
        setValue(key,value);      
    });
  }
}

export const deleteSubProduct = (data) => {
  return async function actionData(dispatch, getState) {
    dispatch(handleInstanceIsLoading());
    try {      
      const res = await apiRequest("delete", `sub-product/`, data);
      if (res?.code == 200) {
        if (res?.data > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          await dispatch(getSubProductList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Delete failed!Please try again",
              code: 100,
            })
          );
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());
    }
  };
};